<template>
  <div>
    <b-navbar v-if="!areSettingsVisible" class="navbar navbar-main d-flex justify-content-between align-content-center" fixed type="light" style="padding: 80px 100px; padding-bottom: 0px">
      <img :src=navbarBrandIcon alt="">
      <div class="d-flex">
        <button v-if="rootManagePermission" @click="$router.push('/manage-organizations')" class="button-no-style"><h5 style="color: #E7217A;">{{ $t("projects.manage_organizations") }}</h5></button>
        <div v-if="rootManagePermission" class="vr vr-blurry ml-2 mr-2" style="background-color: #e9e9e9; height: 3.5rem; width: 1px;"></div>
        <button v-if="userManagePermission" @click="$router.push('/manage-users')" class="button-no-style"><h5 style="color: #E7217A;">{{ $t("projects.manage_users") }}</h5></button>
      </div>
      <!-- <b-navbar-brand><img alt="AIknowYOU" class='navbar__brand navbar__brand--is-active' :src="navbarBrandIcon"></b-navbar-brand> -->
    </b-navbar>

    <b-container :class="['project__container']">
      <span class="project__pageTitle">{{ $t("projects.page_title") }}</span>
      <div :class="`project__wrapper${projects.length == 0 ? '__isHidden' : '' }`">
        <!-- scrollLeft carousel button-->
        <ul v-if="projects.length > 4 ">
          <li :class="`project__carouselArrow${projects.length < 5 ? '__isHidden' : '' } nav__left`" @click="scroll_left()">
          </li> 
        </ul>
        <!-- Carousel -->
        <ul id="carousel" class="project__carousel" v-if="projects">
          <li class="project__flexItem newProject" v-for="(project,index) in projects"  :key="project.id">
            <b-row>
              <b-col style="text-align:end">
                <img :class="'project__flag ' + project.lang + 'Flag'" />
              </b-col>
            </b-row>
            <br>
            <b-row>     
              <b-col>
                <button class="project__button" @click.prevent="goToAdminView(index)"><img class="project__icon" :src="projectNameIcon"></button>
              </b-col>
            </b-row>
            <br>
            <b-row class="text-center">
              <b-col>
                {{ project.name }}
              </b-col>  
            </b-row>
          </li>
        </ul>
        <!-- scrollRight carousel button -->
        <ul v-if="projects.length > 4 ">
          <li :class="`project__carouselArrow${projects.length < 5 ? '__isHidden' : '' } nav__right`" @click="scroll_right()">
          </li> 
        </ul>
        <!-- Modal to create a new project. -->
        <AdminModal
          @cancel-action="closeModal"
          @confirm-action="createProject"
          :activeUser="activeUser"
          :createProject="true"
          description="admin.modals.description.label"
          id="create-project"
          name="admin.modals.name.label"
          ref="modal_1"
          title="admin.modals.create.project.title"
          :type="$t('admin.projects.type')"
        />
      </div>  
      <ul class="project__flexContainer">
        <li>
          <b-row class="text-center">
            <b-col>
              <br>
              <button class="project__addProjectButton" @click="openModal('create-project')">{{ $t("projects.name") }}</button>
            </b-col>  
          </b-row>
          <br>
          <b-row class="text-center">
            <b-col>
              
            </b-col>  
          </b-row>
        </li>
      </ul>
    </b-container>
  </div>  
  
</template>
<script>
  import axios from "axios"; // TODO: refactor needed
  import bus from "@/bus";
  import cookies from "@/common/cookies";
  import icons from "@/styles/_icons.scss";
  import utils from "@/common/utils";
  import ProjectsUsersTable from "../components/aiknowu/TheManageUsers.vue";
  
  import {
    AdminModal,
    Alert,
    AdminSidebar,
    Navbar,
  } from "@/components";
  
  export default {
    name: "Projects",
    components: {
      AdminModal,
      Alert,
      AdminSidebar,
      Navbar,
      ProjectsUsersTable
    },
    data() {
      return {
        navbarBrandIcon: icons.AiKnowYouLogo2022Label,
        projectNameIcon: icons.projectNameIcon,
        activeProject: {},
        activeUser: {},
        adminView: "noProjects",
        areSettingsVisible: false,
        editedItems: [],
        isEdited: false,
        message: "Alert.Discard",
        projects: [],
        users:[],
      }
    },
    created() {
      this.authorizeUser();
      bus.$on("edited", item => {
        this.editedItems.push(item);
        this.isEdited = true;
      });
      bus.$on("saved", () => {
        this.isEdited = false;
      });
    },
    methods: {
      showUsersSetting(){
        this.areSettingsVisible = !this.areSettingsVisible
      },
      goToAdminView(index){
        localStorage.setItem("selectedProject", index)
        this.activeProject = this.projects[index];
        console.log(this.activeProject)
        localStorage.setItem("selectedProjectID", this.activeProject.id )
  
        this.$store.commit("setTheDefaultView", "topicsView");
        this.$router.push("/admin");
      },
      scroll_right() {
        let content = document.querySelector("#carousel");
        content.scrollLeft += 220;
      },
      scroll_left(){
        let content = document.querySelector("#carousel");
        content.scrollLeft -= 220;
      },
      /**
       * Close the selected modal.
       * @param {string} modal - ID of the selected modal.
       */
      closeModal(modal) {
        this.$bvModal.hide(modal);
      },
      /**
       * Discard changes to questions and answers, then close the active modal.
       */
      discardChanges() {
        /**
         * Discard changes to questions and answers.
         */
        this.$refs.topics.updateQasList(this.$refs.topics.activeTopicId);
        this.editedItems = [];
        /**
         * Close the active modals.
         */
        this.closeModal("changes-warning");
        bus.$emit("saved");
        this.$refs.alert.showAlert();
        // TODO: refactor needed
        this.$store.commit("setNavbar", {
          generateBot: true,
          saveActiveTopic: false,
          theActiveProjectTitle: false,
          trainBot: false
        });
        this.$store.commit("setTheDefaultView", "topicsView");
      },
      /**
       * Open the selected modal.
       * @param {string} modal - ID of the selected modal.
       */
      openModal(modal) {
        this.$bvModal.show(modal);
      },
      /**
       * Authorize the user by either `window.localStorage', `window.sessionStorage`, or a cookie-stored token as a fallback.
       */
      authorizeUser() {
        console.log("Authorized")
        let token;
        if (localStorage || sessionStorage) {
          token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
        } else {
          token = cookies.getCookie("jwt");
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      },
      // setUser(){
      //   console.log("setUser function")
      //   console.log(this.activeUser)
      //   payload = {
      //     created_at: this.activeUser.created_at,
      //     id: this.activeUser.id,
      //     name: this.activeUser.name,
      //     organizationId: this.activeUser.org_id,
      //     role: this.activeUser.role,
      //     status: this.activeUser.status
      //   }
      //   this.$store.dispatch('setUser', payload)
      // },
      /**
       * Get active user details
       */
      async getActiveUser() {
        let id;
        if (localStorage || sessionStorage) {
          id = localStorage.getItem("id") ? localStorage.getItem("id") : sessionStorage.getItem("id");
        } else {
          id = cookies.getCookie("id");
        }
  
        try {
          const res = await axios.get(`/user/${id}`);
          if (res.status === 200) {
            this.activeUser = res.data.data;
            // this.setUser()
          }
        }
        catch {}
      },
  
      /**
       * Get the existing projects list, then show it.
       */
      async getProjects() {
        await axios
                .get(`/user/${this.activeUser.id}/projects`)
                  .then(res => {
                    this.projects = res.data.data.projects;
                  });
      },

      async getUsers(){
        await axios
                .get(`/organization/${this.activeUser.org_id}/users`)
                  .then(res => {

                    this.users = JSON.parse(JSON.stringify(res.data.data))
                    console.log(this.users)
                  });
      },
      /**
       * Create a new project, then show it.
       * @param {string} description - Description of the new project (can be empty).
       * @param {string} name - Name of the new project.
       * @param {string} lang - lang of the new project.
       */
      async createProject({
        description,
        name,
        lang
      }) {
        await axios
                .put("/project", {
                  description,
                  name,
                  lang
                })
                  .then(async res => {
                    this.closeModal("create-project");
                    await this.getProjects();
                  });
      },
    },
    async beforeMount() {
      await this.getActiveUser();
      this.getProjects();
      this.navbarBrandIcon = utils.fixSvgImport(this.navbarBrandIcon);
      this.projectNameIcon = utils.fixSvgImport(this.projectNameIcon);
      this.getUsers();
  
    },
    computed: {
      userManagePermission(){
        /* We check if active user can see the manage-user link */
        if (this.activeUser.role == 'root' || this.activeUser.role == 'admin') 
          return true
        else
          return false
      },
      rootManagePermission(){
        if (this.activeUser.role == 'root'){
          return true
        } else {
          return false
        }
      }
    },
    mounted() {
      /**
       * Reset the active modals inputs.
       * @event bvModalEvt
       * @param {string} modalId - ID of the selected modal.
       */
      this.$root.$on("bv::modal::hide", (bvModalEvt, modalId) => {
        if (this.$refs.modal_1) {
          this.$refs.modal_1.form = {};
        }
        if (this.$refs.modal_2) {
          this.$refs.modal_2.form = {};
        }
      });
    }
  };
  </script>